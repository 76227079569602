import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel";
import  "./Testimonials.css"
import Paw from "../assets/Paw-Print-PNG-Image.png"
import React, { useEffect, useRef } from "react";

function Testimonials(){

    const carouselRef = useRef(null);

    useEffect(() => {
        const carouselElement = document.querySelector(".carousel.carousel-slider");

        const handleCarouselTouch = (event) => {
        // Check if the touch started inside the carousel
        if (carouselElement && carouselElement.contains(event.target)) {
            // Prevent carousel swipe by adding a CSS class to disable it
            carouselElement.classList.add("disable-swipe");
        } else {
            // Enable carousel swipe for touches outside the carousel
            carouselElement.classList.remove("disable-swipe");
        }
        };

        // Add touchstart event listener to the document to handle touch events
        document.addEventListener("touchstart", handleCarouselTouch, { passive: false });

        return () => {
        // Remove the touchstart event listener when the component unmounts
        document.removeEventListener("touchstart", handleCarouselTouch);
        };
    }, []);
        
    

        return (


            <div id="testimonials-section">
                <br></br>
                        <h1>Testimonials</h1>
                <br></br>
                <Carousel
                    ref={carouselRef}
                    showArrows={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    interval={4000}
                    swipeable={false} // Disable initial swipe, we'll handle it manually
                >
            
                    <div>
                        
                        <img src={Paw} />
                        <div className="myCarousel">
                            <h3>Ali A.</h3>
                            {/* <h4>18th Apr 2023</h4> */}
                            <p style={{fontSize: "9px"}}>
                            I, like many, got my dog during covid and we have
                            been attached at the hip for the past three years,
                            Being only 9 pounds my dog is my baby and I bring
                            him everywhere with me. When I took on a new job
                                and had to start going into the office there is
                                not many people I would trust with my dog but 
                                I have to say having Eve take him is like 
                                having family take him. She loves him as one 
                                of her own, she sends updates throughout the 
                                day about how he is doing, and can tell she 
                                treats all her dogs with the same love and 
                                care. There is truly no better babysitter for 
                                my dog out there. I know he is so well cared 
                                for and so happy that when works is stressful 
                                I do not have to worry about my baby because 
                                I know he is being loved!
                            </p>
                        </div>
                    </div>

                    <div>
                        <img src={Paw} />
                        <div className="myCarousel">
                            <h3>Bonnie M. </h3>
                            {/* <h4>13th Apr 2023 </h4> */}
                            <p style={{fontSize: "9px"}}>
                            My animals are my family, and Eve treats them as 
                            if they were hers as well. She is trustworthy, 
                            communicative, professional, and truly loves what 
                            she does. Your pet will be lucky to spend time 
                            with Eve!
                            </p>
                        </div>
                    </div>

                    <div>
                        <img src={Paw} />
                        <div className="myCarousel">
                            <h3>Nicole W. </h3>
                            {/* <h4>13th Apr 2023</h4> */}
                            <p style={{fontSize: "10px"}}>
                            We have known Eve now for almost 5 years and I 
                            can’t say enough good things about her. 
                            She takes our mini golden doodle Tucker 3 
                            days a week and loves him like her own. 
                            She is always on time picking him up and 
                            dropping him off. She tires him out completely 
                            with all the running, playing and swimming that 
                            they do.Not only is she amazing with dogs but 
                            the attention she gives my kids when she picks 
                            Tucker up is like no other. It demonstrates the type 
                            of person Eve really is. Caring, trustworthy, generous, 
                            flexible, responsible and friendly are just some of 
                            the words that come to mind when I think of Eve. 
                            We love her!
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={Paw} />
                        <div className="myCarousel">
                            <h3>Nicole T. </h3>
                            {/* <h4>13th Apr 2023 </h4> */}
                            <p style={{fontSize: "8px"}}>
                            I was recommended Eve by a friend who took her 
                            dog to day care. I cannot say enough wonderful
                            things about Eve. Stanley has attended day 
                            care with Eve since he was a puppy. He learned
                            his recall training directly from her, and it
                            carries over into our walks on the beach and
                            in other parks. Stanley launches himself out
                            of my car to get into day care, and if that
                            doesn’t say enough, let me tell you more.
                            Each day Eve and her dad, Juan, load up 
                            the dogs and take them to Victory Park
                            to run and play with other dogs. The
                            dogs are closely watched over and always
                            come home exhausted. When I pick Stanley 
                            up from daycare, Eve is always there 
                            to answer any questions I have about 
                            my dogs sassy personality. Sometimes 
                            he tries to turn back around and get 
                            back into day care instead of coming home 
                            with me!!! Her love for him shows and I know 
                            he is so well taken care of in her hands. 
                            Eve is always flexible to my last minute change 
                            of scheduling. I could not recommend her more 
                            if I tried!
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={Paw} />
                        <div className="myCarousel">
                            <h3>Stella D.  </h3>
                            {/* <h4>13th Apr 2023 </h4> */}
                            <p style={{fontSize: "8px"}}>
                            Last year I had an unexpected emergency surgery. 
                            We had just gotten our new boxer puppy, who was 
                            full of energy. We needed a place for him to go 
                            every day, to run and to play with other dogs. 
                            Someone recommended Eve to us. What a relief it 
                            was to find her! I knew right away how amazing 
                            she was with dogs. Milo was scared the first 
                            time. Eve, gently took Milo and introduced him 
                            to the other dogs. She let him go at his pace.
                            She is truly a dog whisper. She sends me 
                            videos and pictures of Milos day, which always
                            makes me smile. She’s very accommodating. 
                            Anytime I’ve needed her to board Milo she’s been
                            willing to take him. Milo loves going to doggy 
                            daycare with Eve and her helpers. He gets so 
                            excited when we get close to Eves house. It’s 
                            such a great feeling, knowing your pet is being 
                            taken care of. I leave Milo, knowing he is 
                            happy and loved. I never have to worry about 
                            him in Eves care. She’s amazing and very 
                            professional.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={Paw} />
                        <div className="myCarousel">
                            <h3>Jasper J.   </h3>
                            {/* <h4>7th Apr 2023  </h4> */}
                            <p style={{fontSize: "12px"}}>
                            Eve has taken care of my baby girl Pamela for 
                            over a year and half. One of the happiest moments 
                            of Pamela’s everyday is getting picked up by Eve 
                            and going to play with her group of dog friends. 
                            Eve is extremely caring, professional and loving 
                            and does everything she possibly can to ensure 
                            the safety and well-being of my pet. Pamela gets 
                            good exercise everyday and lives a happy life 
                            under Eve’s care. I’m very grateful to have Eve 
                            taking care of Pamela and would 100% recommend 
                            Eve to anyone who owns a pet and truly cares 
                            about their pet’s wellbeing!
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={Paw} />
                        <div className="myCarousel">
                            <h3>Salvatore G.    </h3>
                            {/* <h4>7th Apr 2023 </h4> */}
                            <p>
                            Eve is the best! She is very caring for our dog and is 
                            very professional. Our dog was always so excited for 
                            Eve he would wait by the doors on days he was expecting her :)
                            </p>
                        </div>
                    </div>

                    
                    
                </Carousel>
            </div>
            
        );
    }

    export default Testimonials;