import EveDogFun from "../assets/dogsInCircle.jpg";
import DogOnBed from "../assets/puggy.jpg";
import DogOnRock from "../assets/dogOnRock.jpg";
import PackInHouse from "../assets/PackInHouse.jpg";
import DestinationData from "./DestinationData";
import "./DestinationStyles.css";
import Profile from "../components/ProfileCard"

const Destination = () => {
  return (
    <div className="destination">
      <h1>About Eve</h1>
      <Profile />
      <p>Trust, Reliability and Compassion </p>

      <DestinationData 
      className="first-des"
      heading=""
      text="

      Experience and passion that you should expect from a top-notch pet sitter and dog walker.
      
      I have had the pleasure of turning my passion for dogs into a career, with over 15 years of experience I not only have knowledge but also the drive to meet each and every one of my clients needs.
      
      Has been my full time job for the past 15 years. From belly rubs to hikes and park adventures, runs or leash walks to nail trims and rides to the groomers.
      
      You can choose any of our services, or let's discuss a plan together so we can customize services based on your needs. We are lucky to have a huge fenced in backyard.
      "
      img1={EveDogFun}
      img2={DogOnBed}
      />

<DestinationData 
      className="first-des-reverse"
      heading="What Eve would like to know about your pet..."
      text="Knowing if your dog is afraid of specific things such as sounds or environments, through repetition and having a pack mentality, I have been able to help dogs conquer their fears throughout the years. "
      img1={DogOnRock}
      img2={PackInHouse}
      />

    </div>
  );
};

export default Destination;
