// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.App {\n  text-align: center;\n}\n\n\n/* Adjust the margin-top for the title to move it higher */\n.hero h1 {\n  font-size: 36px;\n  font-weight: 600;\n  color: #fff; /* You can adjust the color as needed */\n  margin-top: -150px; /* Adjust this value to move the title higher or lower */\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;AACpB;;;AAGA,0DAA0D;AAC1D;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW,EAAE,uCAAuC;EACpD,kBAAkB,EAAE,wDAAwD;AAC9E","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.App {\n  text-align: center;\n}\n\n\n/* Adjust the margin-top for the title to move it higher */\n.hero h1 {\n  font-size: 36px;\n  font-weight: 600;\n  color: #fff; /* You can adjust the color as needed */\n  margin-top: -150px; /* Adjust this value to move the title higher or lower */\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
