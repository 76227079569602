import React, { Component } from "react";
import "./DestinationStyles.css";

class DestinationData extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2, // Adjust this threshold value as needed
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer.observe(this.ref.current);
  }

  handleIntersection = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.ref.current.classList.add("aos-animate");
      } else {
        this.ref.current.classList.remove("aos-animate");
      }
    });
  };

  render() {
    return (
      <div
        ref={this.ref}
        className={`aos-container ${this.props.className}`}
      >
        <div className="des-text">
          <h2>{this.props.heading}</h2>
          <p>{this.props.text}</p>
        </div>
        <div className="image">
          <img alt="" src={this.props.img1} />
          <img alt="" src={this.props.img2} />
        </div>
      </div>
    );
  }
}

export default DestinationData;
