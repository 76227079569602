import React, { useState } from 'react';
import './ProfileStyles.css';
import { ImageZoom } from 'react-image-zoom';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography } from 'mdb-react-ui-kit';
import photo from '../assets/danny.jpg';
import italy from '../assets/italy.jpg';
import osaka from '../assets/osaka.jpg';
import beijing from '../assets/beijing.jpg';
import italy2 from '../assets/italy2.jpg';
import india2 from '../assets/india2.JPG';



export default function EditButton() {

   
  return (
    <div className="gradient-custom-2" style={{ backgroundColor: '#fff' }}>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="9" xl="7">
            <MDBCard>
              <div className="rounded-top text-white d-flex flex-row" style={{ backgroundColor: '#000', height: '200px' }}>
                <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                  <MDBCardImage src={photo}
                    alt="Generic placeholder image" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1' }} />
                  {/* <MDBBtn outline color="dark" style={{height: '36px', overflow: 'visible'}}>
                    Edit profile
                  </MDBBtn> */}
                </div>
                <div className="ms-3" style={{ marginTop: '100px' }}>
                  <MDBTypography tag="h5">Danny Gonzalez</MDBTypography>
                  <MDBCardText>Boston, MA</MDBCardText>
                </div>
              </div>
              <div className="p-4 text-black" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="d-flex justify-content-end text-center py-1">
                  <div>
                    <MDBCardText className="mb-1 h5">253</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Photos</MDBCardText>
                  </div>
                  <div className="px-3">
                    <MDBCardText className="mb-1 h5">6</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Yrs Exp</MDBCardText>
                  </div>
                  <div>
                    <MDBCardText className="mb-1 h5">4</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Companies</MDBCardText>
                  </div>
                </div>
              </div>
              <MDBCardBody className="text-black p-4">
                <div className="mb-5">
                <h2 className="heading">Granite Telecommunications MS Representative</h2>
                  <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                    {/* <MDBCardText className="font-italic mb-1">Granite Telecommunications MS Rep</MDBCardText> */}
                    <MDBCardBody>
                    <div className="mb-5">
                    <h4 className="d-inline-block font-weight-normal text-muted">March 2021 - Current</h4>
                    <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                        <ul className="list pl-0">
                        <li className="item mb-4">
                            Configured and maintained Cisco routers and switches, including VLANs, LTE, and routing protocols.
                        </li>
                        <li className="item mb-4">
                            Designed and implemented network topologies using tools such as CiscoVmanage.
                        </li>
                        <li className="item mb-4">
                            Deployed and managed firewalls, including Cisco ASA and Fortinet FortiGate, to secure network traffic and prevent unauthorized access.
                        </li>
                        <li className="item mb-4">
                            Monitored network performance and diagnosed issues using tools such as Wireshark and SolarWinds.
                        </li>
                        <li className="item mb-4">
                            Designed and deployed wireless networks using standards such as 802.11ac and 802.11n.
                        </li>
                        <li className="item mb-4">
                            Implemented network security measures such as VPNs and SSL encryption to secure remote access and web traffic.
                        </li>
                        <li className="item mb-4">
                            Configured and maintained DNS and DHCP servers to manage network traffic and provide IP addresses to clients.
                        </li>
                        <li className="item mb-4">
                            Managed Active Directory and LDAP authentication services to control network access and user permissions.
                        </li>
                        <li className="item">
                            Developed and implemented disaster recovery plans to ensure network availability in case of system failures or disasters.
                        </li>
                        </ul>
                    </div>
                    </div>
                    </MDBCardBody>

                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <MDBCardText className="lead fw-normal mb-0">Recent photos</MDBCardText>
                  {/* <MDBCardText className="mb-0"><a href="#!" className="text-muted">Show all</a></MDBCardText> */}
                </div>
                <MDBRow>
                  <MDBCol className="mb-2">
                    <MDBCardImage src={beijing} alt="image 1" className="w-100 rounded-3" />
                  </MDBCol>
                  <MDBCol className="mb-2">
                    <MDBCardImage src={osaka}
                      alt="image 1" className="w-100 rounded-3" />
                  </MDBCol>
                </MDBRow>
                <MDBRow> {/*className="g-2"*/}
                  <MDBCol className="mb-2">
                    <MDBCardImage src={italy}
                      alt="image 1" className="w-100 rounded-3" />
                  </MDBCol>
                  <MDBCol className="mb-2">
                    <MDBCardImage src={india2}
                      alt="image 1" className="w-100 rounded-3" />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}