// Import the photos
import photo2 from "./images/dogHugging.jpg";
import photo3 from "./images/photo3.jpg";
import photo4 from "./images/photo4.jpg";
import photo5 from "./images/photo5.jpg";
import photo6 from "./images/photo6.jpg";
import photo7 from "./images/photo7.jpg";
import photo8 from "./images/dogsShepard.jpg"
import photo9 from "./images/EveDogFun.jpg"
import photo10 from "./images/smallDoggy.jpg"
import photo11 from "./images/aussie.jpg"
import photo12 from "./images/dogsLooking.jpg"

const photos = [
  { src: photo9, width: 3840, height: 2839 },
  { src: photo2, width: 3840, height: 5500 },
  { src: photo3, width: 3840, height: 4000 },
  { src: photo4, width: 3840, height: 3559 },
  { src: photo5, width: 3840, height: 5760 },
  { src: photo6, width: 3840, height: 3500 },
  { src: photo7, width: 3840, height: 3000 },
  { src: photo8, width: 3840, height: 3000 },
  { src: photo10, width: 3840, height: 4500 },
  { src: photo11, width: 3840, height: 4500 },
  { src: photo12, width: 3840, height: 3000 },

  // Add more photos here if needed
];

const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

export const photosWithSrcSet = photos.map((photo) => {
  const width = 1080; // Set the width to 1080 pixels
  const height = (photo.height / photo.width) * width;

  return {
    src: photo.src,
    width,
    height,
    srcSet: breakpoints.map((breakpoint) => {
      const height = Math.round((photo.height / photo.width) * breakpoint);
      const width = breakpoint;
      return {
        src: photo.src,
        width,
        height,
      };
    }),
  };
});
