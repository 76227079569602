import React from "react";
import mapImage from "../assets/staticmap.png";
import "./MapComponent.css"; // Create this CSS file in the same directory

const MapComponent = () => {
  return (
    <div className="map-section">
      {/* <div className="map-container">
        <h2 className="section-title">Eve's Local Area</h2>
        <img className="map-image" src={mapImage} alt="Map" />
      </div> */}
      <div className="description-section">
        <h2 className="section-title">Eve's pet priorities</h2>
        <p>
          My main focus is to provide dogs with plenty of exercise and socialization.
          Whether it's playing fetch, going for a run, or a dip in the ocean.
          A well-exercised dog is a happy dog.
        </p>
        <hr></hr>
        <ul className="check-list">
          <li>Dogs Allowed On Bed</li>
          <li>Potty Breaks Every 0-2 Hours</li>
          <li>Dogs Allowed On Furniture</li>
        </ul>
        <br></br>
        <div className="skills-section">
        <div className="skill-column">
          <h4>Additional Skills</h4>
          <hr></hr>
          <ul className="check-list">
            <li>Oral Medication Administration</li>
            <li>First Aid/CPR</li>
            <li>Special Needs Experience</li>
            <li>Daily Exercise Routines</li>
          </ul>
        </div>
        <div className="skill-column">
          <h4>About Eve's Home</h4>
          <hr></hr>
          <ul className="check-list">
            <li>Lives in a House</li>
            <li>Non-Smoking House</li>
            <li>No Children Present</li>
            <li>Has a Fenced in Garden</li>
            <li>Has 1 Dog, no Other Pets</li>
          </ul>
        </div>
      </div>

        
      </div>
      
    </div>
  );
};

export default MapComponent;
