import React from 'react';
import './FooterStyles.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h5 className="mb-4">Eve Sandoval Wolfson</h5>
            {/* <p className="mb-0">123 Main Street</p> */}
            <p className="mb-0">Dorchester & Milton Area</p>
            {/* <p className="mb-0">Quincy, MA 02169</p> */}
            <p className="mb-0 desktop-only">857 928 0183</p>
            <p className="mobile-only">
              <a rel="noopener" target="_blank" href="tel:(857)928-0183"><i className="fa fa-phone" aria-hidden="true"></i></a>
              <a rel="noopener" target="_blank" href="sms:(857)928-0183?&body=Give%20me%20a%20call" className="sms-link"><i className="fa fa-sms" aria-hidden="true"></i></a>
            </p>
          </div>
          <div className="col-sm-12 col-md-6">
            <h5 className="mb-4">Inquire Today</h5>
            <a href="https://www.instagram.com/dogcodeboston/"><i className="fab fa-instagram"></i></a>
            <a href="mailto:dogcodeboston@gmail.com"><i className="far fa-envelope"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;