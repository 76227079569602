import "./ContactFormStyles.css";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';


function ContactForm() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bupax03', 'template_k897gwy', form.current, 'user_gZ383AXTPpAkynCYDBuIn')
      .then((result) => {
          console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
          })
      }, (error) => {
          console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
          })
      });
      e.target.reset()
  };


  return (
    <div className="from-container">
  <form ref={form} onSubmit={sendEmail}>
    <h1>Contact Me</h1>
    <label>Name</label>
    <input type="text" name="user_name" placeholder="Your Name" />
    <label>Email</label>
    <input type="email" name="user_email" placeholder="Your Email" />
    <label>Message</label>
    <textarea name="message" placeholder="Type your message here..." />
    <input type="submit" value="Send Message" />
  </form>
</div>

  );
}

export default ContactForm;
