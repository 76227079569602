import "./styles.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Service from "./routes/Service";
import Contact from "./routes/Contact";
import Navbar from "./components/Navbar";


export default function App() {
  return (
    <div className="App">
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Home />} />
        <Route path="/service" element={<Home />} />
        <Route path="/contact" element={<Home />} />
        
        <Route path="*" element={<Navigate to="/about" replace />} />

      </Routes>


    </div>
  );
}
