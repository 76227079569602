import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/design.jpg";
import Footer from "../components/Footer";
import Trip from "../components/Trip";

function Service() {
  return (
    <>
      <Navbar/>
      <Hero 
      cName="hero"
      heroImg={AboutImg}
      title="Curriculum Vitae"
      text="Boston Based"

      />

    </>
  );
}

export default Service;
