import React from 'react';
import './CardPrices.css'; // Create this CSS file in the same directory
//import dogSleep from "../assets/sleepover.jpg"
import dogSleep from "../assets/overnights.jpg"
//import daycare from "../assets/daycare.jpg"
import daycare from "../assets/DogDayCare.jpg"
//import dogwalking from "../assets/dogwalk.jpg"
import dogwalking from "../assets/playgroup.jpg"

const CardPrices = ({ imageSrc, title, description, price }) => {
  return (
    <div className="card-prices">
      <div className="card-header">
        <img src={imageSrc} alt="card-image" />
      </div>
      <div className="card-body">
        <h4 className="card-title">{title}</h4>
        <p className="card-text">{description}</p>
        <h6>{price}</h6>
      </div>
    </div>
  );
};

    const CardPricesSection = () => {
        const cardData = [
          {
            imageSrc: dogSleep,
            title: 'Boarding',
            // description: "In the sitter's home. Your dog will stay overnight.",
            price: '$100 per night'
          },
          // Add more card data here
          {
            imageSrc: dogwalking,
            title: 'Playgroup',
            description: '90 minutes of off-leash adventures, pickup and drop-off included.',
            price: '$45'
          },
          {
              imageSrc: daycare,
              title: 'Doggy Day Care',
              // description: "Pickup is between 9 and 9:30. Drop-off is between 4 and 4:30",
              price: '$60 per day'
          },
          
        ];
      

  return (
    <div className="card-prices-section">
      <div className="section-title">
        <h2>Pricing</h2>
        <p>Choose the best option for your furry friend.</p>
      </div>
      
      <div className="card-list">
        {cardData.map((card, index) => (
          <CardPrices
            key={index}
            imageSrc={card.imageSrc}
            title={card.title}
            description={card.description}
            price={card.price}
          />
        ))}
      </div>
    </div>
  );
};

export default CardPricesSection;


