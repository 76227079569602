import "./TripStyles.css";
import TripData from "./TripData";
import Calendar from "../assets/Calendar.jpg";
import Trip2 from "../assets/admin.png";
import Trip3 from "../assets/masm.webp";
import Aboutpdf from "../assets/MatrixScript-master.zip";
import Aboutpdf2 from "../assets/AssemblyProject-master.zip";

function Trip() {
  return (
    <div className="trip">
      <h1>Availability </h1>
      <p>Search for a time to Board, Walk, or Daycare</p>
      <div className="tripcard">
        <TripData image={Calendar}
        heading=" Calendar"
        text="This will be a Calendar of Availablility "
        url={Calendar}
        buttonText="Download"
        btnClass="show"

        />

       
      </div>
    </div>
  );
}

export default Trip;