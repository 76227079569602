import "./HeroStyles.css";
import Profile from "../components/ProfileCard"

function Hero(props) {
  return (
    <>
      <div className={props.cName}>
        
        <img src={props.heroImg}/>
        
        <div className="hero-text">
          
          <div className="hero-background">
            
            <h1>{props.title}</h1>
            <p>{props.text}</p>
            <a href={props.url} className={props.btnClass} download>
              {props.buttonText}
            </a>
            
          </div>
          
        </div>
        
      </div>
      
    </>
  );
}

export default Hero;
