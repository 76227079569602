import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/lantern.jpg";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import ProfilePage from "../components/Profile";

function About() {
  return (
    <>
      <Navbar/>
      <Hero 
      cName="hero-mid"
      heroImg={AboutImg}
      title="Bio"
      
      btnClass="hide"
      />
      <ProfilePage />

    </>
  );
}

export default About;
