import React, { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { photosWithSrcSet } from "./photos"; // Import 'photosWithSrcSet'
import "./ImageGallery.css"; // Import the CSS styles

export default function ImageGallery() {
  const [index, setIndex] = useState(-1);

  return (
    <div className="container">
        
        <div className="content">
            <PhotoAlbum
            photos={photosWithSrcSet}
            layout="rows"
            targetRowHeight={150}
            onClick={({ index }) => setIndex(index)}
            />
            <Lightbox
            slides={photosWithSrcSet}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            // enable optional lightbox plugins
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </div>
    </div>
  );
}
