import React from 'react';
import Photo1 from "../assets/photo1.jpg";
import Decal from "../assets/dogBones.png";
import DogToys from "../assets/dogBones.png";
import { MDBContainer, MDBCardImage, MDBCardText, MDBBtn, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';

export default function ProfileStatistics() {
  return (
    <MDBContainer>
      <div className="profile-card">
        <div className="profile-image-container">
          {/* Decal on the right side */}
          <div className="decal-container">
            <img src={Decal} alt="Decal" className="decal" />
          </div>
          
          <MDBCardImage src={Photo1} alt="Profile Photo" className="profile-image rounded-pill" />

          {/* Decal on the left side */}
          <div className="decal-container left">
            <img src={DogToys} alt="Decal" className="decal" />
          </div>
        </div>
        <MDBTypography tag="h4">Eve Sandoval</MDBTypography>
        <MDBCardText className="text-muted mb-4">
          <a href="#!">dogcodeboston</a>
          <a href="#!">@gmail.com</a>
        </MDBCardText>
        <MDBBtn outline floating>
          <a href="https://www.instagram.com/dogcodeboston/">
            <MDBIcon fab icon="instagram" size="lg" />
          </a>
        </MDBBtn>
        <MDBBtn outline floating>
          <a href="mailto:dogcodeboston@gmail.com">
            <MDBIcon far icon="envelope" size="lg" />
          </a>
        </MDBBtn>
        <MDBBtn outline floating>
          <a href="tel:(857)928-0183">
            <MDBIcon fas icon="phone-volume" size="lg" />
          </a>
        </MDBBtn>
        <MDBBtn outline floating>
          <a href="sms:(857)928-0183?&body=Give%20me%20a%20call">
            <MDBIcon fas icon="sms" size="lg" />
          </a>
        </MDBBtn>
      </div>
    </MDBContainer>
  );
}
