import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./NavbarStyles.css";
import DogCodeLogo from "../assets/dogCodeLogo.png";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 750px)").matches); // Initial screen size
  useEffect(() => {
    // Scroll to top on location change
    window.scrollTo(0, 0);

    // Add event listener to close mobile menu on screen width change
    const handleScreenResize = () => {
      const newIsMobile = window.matchMedia("(max-width: 750px)").matches;
      setIsMobile(newIsMobile);
      if (!newIsMobile) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleScreenResize);

    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, [location]);

  const handleHamburgerClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -150; // Adjust this value to your preference
      const sectionTop = section.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo({ top: sectionTop, behavior: "smooth" });
      setMobileMenuOpen(false); // Close the mobile menu
    }
  };

  const MenuItems = [
    {
      title: "A b o u t",
      sectionId: "about",
      cName: "nav-links",
    },
    {
      title: "A l b u m",
      sectionId: "album",
      cName: "nav-links",
    },
    {
      title: "P r i c i n g",
      sectionId: "pricing",
      cName: "nav-links",
    },
    {
      title: "T e s t i m o n i a l s",
      sectionId: "ratings",
      cName: "nav-links",
    },
  ];

  return (
    <nav className="NavbarItems">
      {/* Your logo elements */}
      {/* Logo on the far right */}
      <div className="navbar-logo">
        <img className="nav-logo" src={DogCodeLogo} alt="DogCode Logo" />
      </div>

      {/* Hamburger icon */}
      <div className={`menu-icons ${mobileMenuOpen ? "open" : ""}`} onClick={handleHamburgerClick}>
        <div className={`bar1 ${mobileMenuOpen ? "open" : ""}`}></div>
        <div className={`bar2 ${mobileMenuOpen ? "open" : ""}`}></div>
        <div className={`bar3 ${mobileMenuOpen ? "open" : ""}`}></div>
      </div>

      {/* Navbar Links (Modal-like) */}
      {isMobile && mobileMenuOpen && (
        <ul className={`nav-menu mobile-menu ${mobileMenuOpen ? "slide-in" : ""}`}>
          {MenuItems.map((item, index) => (
            <li key={index}>
              <button className={item.cName} onClick={() => handleScrollToSection(item.sectionId)}>
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      )}

      {/* Desktop View - Render Original Links */}
      {!isMobile && (
        <ul className="nav-menu desktop-menu">
          {MenuItems.map((item, index) => (
            <li key={index}>
              <button className={item.cName} onClick={() => handleScrollToSection(item.sectionId)}>
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
