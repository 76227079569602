import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/meeting2.jpg";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

function Contact() {
  return (
    <>
      <Navbar/>
      <Hero 
      cName="hero-contact"
      heroImg={AboutImg}
      title="Inquire"
      text="Boston Based"

      />
      <ContactForm />

    </>
  );
}

export default Contact;
