import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Destination from "../components/Destination";
import ImageGallery from "../components/ImageGallery";
import MapComponent from "../components/MapComponent";
import CardPrices from "../components/CardPrices";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import AboutImg from "../assets/dogBackground-transformed.jpeg";

function Home() {
  const [scrollTarget, setScrollTarget] = useState(null);
  const albumRef = useRef(null);

  // Effect to scroll to the desired section
  useEffect(() => {
    if (scrollTarget) {
      const section = document.getElementById(scrollTarget);
      if (section) {
        let offset = 0; // Default offset
        if (scrollTarget === "album" || scrollTarget === "pricing" || scrollTarget === "ratings") {
          offset = -200; // Adjust this value for the desired offset
        }
        const sectionTop = section.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({ top: sectionTop, behavior: "smooth" });
        setScrollTarget(null); // Reset the scroll target after scrolling
      }
    }
  }, [scrollTarget]);

  const handleSectionClick = (sectionId) => {
    setScrollTarget(sectionId);
  };

  return (
    <>
      <Navbar onSectionClick={handleSectionClick} />
      <Hero
        cName="hero"
        heroImg={AboutImg}
        title="DogCode"
        text="Dorchester & Milton"
        // buttonText="Button"
        // url={Aboutpdf}
        // btnClass="show"
      />

      <div id="about"></div>
      <div data-aos="fade-up">
        <Destination />
      </div>

      <div id="album" ref={albumRef}></div>
      <h3>Eve's Album</h3>
      <ImageGallery />

      <br />

      <MapComponent />
      <div id="pricing"></div>
      <CardPrices />
      <div style={{ marginBottom: "30px" }}>
        <div id="ratings"></div>
        <Testimonials />
      </div>
      <Footer />
    </>
  );
}

export default Home;
